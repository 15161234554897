import React, { Component } from "react";
import Header from "../../Design/header";
import "./About.css";
class About extends Component {
  render() {
    return (
      <div>
        <Header />
        <p className="BodyMain">
          ow see. Called person are around county talked her esteem. Those fully
          these way nay thing seems. Six started far placing saw respect females
          old. Civilly why how end viewing attempt related enquire visitor. Man
          particular insensible celebrated conviction stimulated principles day.
          Sure fail or in said west. Right my front it wound cause fully am
          sorry if. She jointure goodness interest debating did outweigh. Is
          time from them full my gone in went. Of no introduced am literature
          excellence mr stimulated contrasted increasing. Age sold some full
          like rich new. Amounted repeated as believed in confined juvenile.
        </p>
      </div>
    );
  }
}

export default About;
